import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import VideoIndex from '../views/video/index.vue'
import VideoPlayer from '../views/video/player.vue'
import LectureIndex from '../views/lecture/index.vue'
import LecturePlayer from '../views/lecture/player.vue'
import RadioIndex from '../views/radio/index.vue'
import RadioPlayer from '../views/radio/player.vue'
import EssenceIndex from '../views/essence/index.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    name: 'video',
    path: '/video',
    component: VideoIndex,
    meta: {
      title: '影音',
    },
  },
  {
    path: '/video/player/:aid',
    name: 'video',
    component: VideoPlayer,
    meta: { title: '播放器'},
  },
  {
    path: '/video/player/:aid/:id',
    name: 'video',
    component: VideoPlayer,
    meta: { title: '播放器'},
  },
  {
    name: 'lecture',
    path: '/lecture',
    component: LectureIndex,
    meta: {
      title: '讲演全集',
    },
  },
  {
    path: '/lecture/player/:num',
    name: 'lecture',
    component: LecturePlayer,
    meta: { title: '播放器'},
  },
  {
    path: '/lecture/player/:num/:id',
    name: 'lecture',
    component: LecturePlayer,
    meta: { title: '播放器'},
  },
  {
    name: 'radio',
    path: '/radio',
    component: RadioIndex,
    meta: {
      title: '电台',
    },
  },
  {
    name: 'radio',
    path: '/radio/player/:aid',
    component: RadioPlayer,
    meta: {
      title: '播放器',
    },
  },
  {
    name: 'radio',
    path: '/radio/player/:aid/:id',
    component: RadioPlayer,
    meta: {
      title: '播放器',
    },
  },
  {
    name: 'essence',
    path: '/essence',
    component: EssenceIndex,
    meta: {
      title: '精华',
    },
  },
  {
    name: 'essence',
    path: '/essence/view/:id',
    component: () => import('@/views/essence/view'),
    meta: {
      title: '精华内容',
    },
  },
  {
    name: 'info',
    path: '/info',
    component: () => import('@/views/info/index'),
    meta: {
      title: '资讯',
    },
  },
  {
    name: 'info',
    path: '/info/view/:id',
    component: () => import('@/views/info/view'),
    meta: {
      title: '资讯内容',
    },
  },
  {
    name: 'live',
    path: '/live',
    component: () => import('@/views/live/index'),
    meta: {
      title: '直播',
    },
  },
  {
    name: 'live',
    path: '/live/:id',
    component: () => import('@/views/live/index'),
    meta: {
      title: '直播',
    },
  },
  {
    name: 'product',
    path: '/product',
    component: () => import('@/views/product/index'),
    meta: {
      title: '产品',
    },
  },
]

const router = new VueRouter({
  routes,
  mode: "history",
})

export default router
