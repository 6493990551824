<template>
  <header>
    <div class="cg-top">
      <div class="cg-container cg-row">
        <div class="cg-logo">
          <img class="cg-log-img" src="../assets/image/logo-慈光講堂@2x.png" />
        </div>
        <div class="cg-navigation cg-row">
          <div class="cg-navigation-top cg-row">
            <div class="cg-share">
              <ul class="cg-share-nav">
                <li class="cg-share-nav-item">
                  <a href>
                    <img src="../assets/image/微信.png" class="cg-share-nav-img" />
                  </a>
                </li>
                <li class="cg-share-nav-item">
                  <a href>
                    <img src="../assets/image/喜馬拉雅聽.png" class="cg-share-nav-img" />
                  </a>
                </li>
                <li class="cg-share-nav-item">
                  <a href>
                    <img src="../assets/image/微博.png" class="cg-share-nav-img" />
                  </a>
                </li>
                <li class="cg-share-nav-item">
                  <a href>
                    <img src="../assets/image/youtube.png" class="cg-share-nav-img" />
                  </a>
                </li>
                <li class="cg-share-nav-item">
                  <a href>
                    <img src="../assets/image/facebook.png" class="cg-share-nav-img" />
                  </a>
                </li>
              </ul>
            </div>
            <div class="cg-date cg-row">
              <div>
                <img src="../assets/image/齋.png" class="cg-date-img" />
                <span class="cg-date-title">{{ date_title }}</span>
              </div>
            </div>
          </div>
          <div class="cg-navigation-bottom cg-row">
            <ul class="cg-nav">
              <li
                v-for="(item, index) in nodelist"
                :key="index"
                v-bind:class="[node_select_index == item.name ? 'cg-nav-item-select' : '', 'cg-nav-item']"
              >
                <router-link :to="item.url">{{ item.title }}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>

export default {
  name: "HeaderView",
  props: {
    msg: String
  },
  data() {
    return {
      nodelist: [
        { title: "首页", url: "/", name: "home" },
        { title: "資訊", url: "/info", name: "info" },
        { title: "直播", url: "/live", name: "live" },
        { title: "點播", url: "/video", name: "video" },
        { title: "電台", url: "/radio", name: "radio" },
        { title: "精華", url: "/essence", name: "essence" },
        // { title: "圖集", url: "/picture", name: "picture" },
        // { title: "慈光出品", url: "/product", name: "product" },
        { title: "影音全集", url: "/lecture", name: "lecture" }
      ],
      date_title: "佛歷二五五四年",
      node_select_index: this.$route.name,
      playlist_num: 0,
      timer: null,
      open_sub_menu: false,
      mouse_on_sub_menu: false
    };
  },
  watch: {
    $route(to, from) {
      if (to.name !== from.name) {
        this.node_select_index = this.$route.name.split(" ")[0];
      }
    }
  },
  methods: {
    click(event) {
      console.log("top: ", event.clientY);
    },
    getNewPlayListNum() {
      let store = localStorage.getItem("new-playlist-num");
      if (store !== null) {
        this.playlist_num = JSON.parse(store);
      }
    },
    openSubMenu(event) {
      console.log("mouse enter");
      event.stopPropagation();
      this.open_sub_menu = true;
    },
    enter() {
      this.mouse_on_sub_menu = true;
    },

    handleSubMenu() {
      setTimeout(() => {
        if (this.mouse_on_sub_menu) return;
        this.open_sub_menu = false;
      }, 300);
    },
    closeChild() {
      this.mouse_on_sub_menu = false;
      this.open_sub_menu = false;
    },
    goTo(url){
      this.$router.push({ path: url}) 
    },
  },
  created() {
    this.timer = setInterval(this.getNewPlayListNum, 3000);

  },
  beforeDestroy() {
    this.timer = null;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cg-top {
  width: 100%;
  height: 125px;
  background-color: #fcfcfc; 
  margin-bottom: 10px;
}
.cg-logo {
  float: left;
  width: 345px;
}
.cg-log-img {
  height: 100px;
  margin: 5px 0 15px 0;
}
.cg-logo-right {
  float: right;
  padding-top: 40px;
}
.cg-user-caret-bottom {
  font-size: 20px;
}
.cg-navigation {
  float: left;
}
.cg-share {
  float: left;
  width: 206px;
}
.cg-share-nav-item {
  float: left;
  padding-right: 15px;
}
.cg-share-nav-img {
  height: 24px;
}
.cg-date {
  float: left;
  padding-left: 30px;
}
.cg-date-img {
  height: 24px;
  padding-right: 10px;
}
.cg-date-title {
  height: 24px;
  line-height: 24px;
}
.cg-navigation-top {
  display: block;
  height: 40px;
  padding-top: 20px;
}

.cg-navigation-bottom {
  display: block;
  height: 45px;
  line-height: 45px;
  margin-bottom: 20px;
}
.cg-nav-item {
  float: left;
  padding-right: 25px;
}
.cg-nav-item a {
  color: #606266;
  font-size: 16px;
  text-decoration: none;
}
.cg-nav-item a:hover {
  color: rgba(29, 171, 255, 0.8);
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(29, 171, 255, 1);
}
.cg-nav-item-select a {
  color: rgb(225, 143, 9);
  padding-bottom: 5px;
  border-bottom: 1px solid rgb(225, 143, 9);
}
.cg-playlist-nav,
.cg-playlist-nav-select {
  font-size: 15px;
  color: #606266 !important;
  text-decoration: none !important;
}
.el-dropdown-menu li a {
  text-decoration: none !important;
}
.cg-playlist-nav:hover,
.cg-playlist-nav-select:hover {
  color: rgba(29, 171, 255, 0.8) !important;
}
.cg-playlist-nav-select {
  color: teal !important;
}
.favorite-icon {
  padding-left: 7px;
}
.favorite-icon i {
  font-size: 10px;
  font-weight: bold;
}
</style>
