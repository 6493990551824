<template>
  <main>
    <div class="cg-content cg-row">
        <div class="cg-content-right">
          <div class="cg-daoshi-img">
            <template >
              <el-carousel  :interval="5000" arrow="always" height="350px">
                <el-carousel-item v-for="(item, index) in swipeList" :key="index">
                  <a target="_blank" @click="itemLink('focus',index)" class="cg-link"><img :src = "item.cover" class="foucs-image" ></a>
                </el-carousel-item>
              </el-carousel>
            </template>
          </div>
        </div>
        <div class="cg-qrcod  cg-link">
          <img class="cg-qrcode-img"  @click="goApp()" src="https://i.yyii.info/storage/preview/1200/picture/2024/09/821264d7ad683ba80f61a252581f307c.jpg"  />
        </div>
      </div>
    <div class="cg-infolist cg-row">
        <template>
          <div class="cg-infolist-title">
            <span class="">最近更新</span>
            <el-divider direction="vertical"></el-divider>
            <span>更多</span>
          </div>
        </template>
        <div class="">
          <el-row>
            <el-col :span="6" 
            v-for="(item, index) in recommendList" 
            :key="index">
              <a target="_blank" @click="itemLink('home',index)" class="cg-link">
                <el-card :body-style="{ padding: '0px' }" shadow="hover" class="cg-infolist-card">
                  <img :src="item.cover" class="image">
                  <div>
                    <span class="cg-infolist-card-title">{{ item.title }}</span>
                    <div class="bottom clearfix">
                      <time class="cg-infolist-card-time">{{ item.date }}</time>
                      <div class="cg-infolist-card-modelname">{{ item.model_name }}</div>
                    </div>
                  </div>
                </el-card>
              </a>
            </el-col>
          </el-row>
        </div>
    </div>
  </main>
</template>

<script>
import { getHomeFocusList, getHomeRecommend} from '@/common/api_cg';
import { getUrl } from "@/common/tools";
export default {
  name: 'HomeView',
  props: {
    msg: String
  },
  data(){
    return{
      swipeList : {},
      recommendList : {},
      pageInfo: {},
    };
  },
  methods:{
    itemLink(type,index){
      console.log(type);
      let item = (type=='home') ?  this.recommendList[index] :  this.swipeList[index];
      //console.log(getUrl(item));
      this.$router.push({ path: getUrl(item) })
    },
    goApp(){
      window.open('https://h5.jingzong.net/cg/179' ,'_blank')
    },
    fetchData() {
      //加載焦點圖
      getHomeFocusList({}).then(res => {
        this.swipeList = res.data.rows
      }) 
      getHomeRecommend({page:this.pageInfo.page}).then(res => {
        this.recommendList = res.data.rows
        this.pageInfo = res.data.page
      }) 
    },
    goTo(index){
      let item = this.list[index]
      this.$router.push({ path: getUrl(item) })
    }
  },
  created(){
      this.fetchData()
      //加載公告列表
      // this.axios.get(this.$myStore.api + 'info/article?type=new&' + this.$myStore.client)
      // .then(response => {
      //   this.announce_list = response.data.data.rows
      // }),

      // //加載焦點圖
      // this.axios.get(this.$myStore.api + 'info/index?type=focus&' + this.$myStore.client)
      // .then(response => {
      //   this.focus_list = response.data.data.rows
      // })
      // //加載最近更新
      // this.axios.get(this.$myStore.api + 'info/index?type=home&' + this.$myStore.client)
      // .then(response => {
      //   this.info_list = response.data.data.rows
      // })      
  },
  metaInfo: {
    title: '慈光講堂',
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no' }
    ],
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .cg-content{
        margin-bottom: 15px;
    }
    .cg-content-left{
        width: 420px;
        height: 340px;
        float:left;
        padding:10px 0 0 15px;
        background-color: #fbfbfb; 
        margin-right:10px;
    }
    .cg-qrcod{
        width: 420px;
        height: 350px;
        float:left;
        background-color: #fbfbfb; 
        margin-right:10px;
    }
    .cg-content-right{
        width: 835px; 
        height: 350px;
        float:right;
        background-color: #e7e7e7; 
    }
    .cg-announce-list-item{
        height:32px;
        line-height: 32px;
    }
    .cg-daoshi-img{
      float:left; 
      width: 835px;
    }
    .foucs-image{
        height:350px;
        width: 835px;
        object-fit: cover;
    }  
</style>
