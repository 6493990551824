/**   
 * api接口统一管理
 */
import { get } from './http_cg.js'
export const getHomeGridList = p => get('home/grid', p);
export const getHomeFocusList = p => get('home/focus', p);
export const getHomeRecommend = p => get('home/recommend', p);
export const getVideoList = p => get('video/list', p);
export const getCategoryList = p => get('video/category', p);
export const getAlbumList = p => get('video/album', p);
export const getVideoText = p => get('video/text', p);
export const getLectureVideoList = p => get('lecture/list', p);
export const getLectureCategoryList = p => get('lecture/category', p);
export const getLectureAlbumList = p => get('lecture/album', p);
export const getRadioCategoryList = p => get('radio/category', p);
export const getRadioAlbumList = p => get('radio/album', p);
export const getRadioList = p => get('radio/list', p);
export const getEssenceCategoryList = p => get('essence/category', p);
export const getEssenceTagList = p => get('essence/tag', p);
export const getEssenceList = p => get('essence/list', p);
export const getEssenceDetail = p => get('essence/detail', p);
export const getInfoCategoryList = p => get('info/category', p);
export const getInfoList = p => get('info/list', p);
export const getInfoDetail = p => get('info/detail', p);
export const getPictrueCategoryList = p => get('picture/category', p);
export const getPictrueAlbumList = p => get('picture/album', p);
export const getPictrueList = p => get('picture/list', p);
export const getChannelList = p => get('live/channel', p);
export const getTimetableList = p => get('live/timetable', p);
export const getSearchList = p => get('home/search', p);
export const getQuanAlbumList = p => get('lecture/qj_album', p);